.kelp1 {
    position: absolute;
    top: 10.5%;
    left: -60%;
    z-index: 9;
    width: 100%;
    height: 100%;
    transform: rotate(-9.6deg);
    transform-origin: bottom;
    scale: 1;
    animation: kelp1-wave 8s infinite linear;
  }
  
  .kelp2 {
    position: absolute;
    bottom: -1.2%;
    left: -14.6%;
    z-index: 9;
    width: 100%;
    height: 100%;
    transform-origin: bottom;
    transform: rotate(-3.4deg);
    scale: 1;
    animation: kelp2-wave 10s infinite linear;
  }
  
  .manatee {
    position: absolute;
    top: 12%;
    left: 12%;
    z-index: 8;
    width: 100%;
    height: 100%;
    scale: 1;
    animation: float 8s infinite linear;
  }
  
  .shark1 {
    position: absolute;
    bottom: 12%;
    right: 8%;
    z-index: 3;
    width: 228px;
    height: 96px;
    width: 100%;
    height: 6rem;
    scale: 1;
    animation: float 6s infinite linear;
  }
  
  .shark2 {
    position: absolute;
    top: 26%;
    left: -66%;
    z-index: 2;
    width: 100%;
    height: 4rem;
    scale: 1;
    animation: float 10s infinite linear;
  }

  svg {
    filter: drop-shadow(2px 2px 12px hsl(240deg 60% 12%));
  }

  @keyframes float {
    0% {
      transform: rotate(0deg) translate(-2px) rotate(0deg);
  
    }
    100% {
      transform: rotate(360deg) translate(-2px) rotate(-360deg);
    }
  }

  @keyframes kelp1-wave {
    0% {
      transform: rotate(-12deg);
    }
    50% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(-12deg);
    }
  }

  @keyframes kelp2-wave {
    0% {
      transform: rotate(-2deg);
    }
    50% {
      transform: rotate(-4deg);
    }
    100% {
      transform: rotate(-2deg);
    }
  }

  @container (min-width: 400px) {

    .shark1 {
      width: 100%;
      height: 6rem;
      /* scale: 1.4; */
    }

  }

  @container (min-width: 750px) {
  }