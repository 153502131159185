.title-container {
  /* width: 100%; */
  font-family: "Arial";
  font-style: narrow;
  font-weight: 900;
}

.logo-title {
  position: relative;
  top: 4rem;
  margin: 0rem 0rem 0rem 0rem;
  width: 100%;
  height: 100%;
}

@container (min-width: 400px) {
  .logo-title {
    top: 0rem;
    scale: 1.5;
  }
}

@container (min-width: 750px) {
  .logo-title {
    top: 0rem;
    scale: 1.5;
  }
}

.title-be-the {

}

.title-manatee {

}
