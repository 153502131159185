.under-construction {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin: auto;
  padding: 2rem;
  width: calc(100% - 4rem);
  height: 100%;
}

.under-construction-header {
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  /* height: 20%; */
  /* height: auto; */
}

.under-construction-body {
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 3rem 0 0 0;
  width: 100%;
  height: 80%;
  /* height: auto; */
}

.logo-title-holder,
.logo-holder,
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;
}

.logo-title-holder {
  /* width: 100%; */
  /* height: 100%; */
}

.logo-holder {
  width: 100%;
  /* height: 28rem; */
  /* height: calc(50% - 3.5rem); */
}

.logo {
  /* width: 100%; */
  /* height: 100%; */
  /* width: 28rem; */
  /* height: 28rem; */
  scale: 0.6;
}

.coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* top: 48px; */
  margin: 0px auto;
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  font-family: "Arial Narrow Bold";
  color: hsl(72deg 36% 96%);
  filter: drop-shadow(2px 2px 8px hsl(240deg 60% 12%));
}

@container (min-width: 400px) {
  
  .logo-title-holder {
    /* width: 100%; */
    /* height: 24%; */
  }

  .logo {
    margin: 0;
    /* width: 28rem; */
    /* height: 28rem; */
    scale: .85
  }

  .coming-soon {
    top: 3rem;
  }
}

@container (min-width: 750px) {
  
  .logo-title-holder {
    /* padding: 3rem 0 0 0; */
    /* width: 100%; */
    /* height: 36%; */
  }

  .logo {
    margin: 0;
    /* width: 28rem; */
    /* height: 28rem; */
    scale: 1
  }

  .coming-soon {
    top: 3rem;
  }
}