body {
  /* hsl(240deg 60% 12%) */
  --color-water-deep-h: 222;
  --color-water-deep-s: 56%;
  --color-water-deep-l: 32%;
  /* hsl(222, 56%, 32%) */
  --color-water-dark-h: 222;
  --color-water-dark-s: 56%;
  --color-water-dark-l: 32%;

  --water-deep: hsl(
    var(--color-water-deep-h),
    var(--color-water-deep-s),
    calc(var(--color-water-deep-l))
  );

  --water-dark: hsl(
    var(--color-water-dark-h),
    var(--color-water-dark-s),
    calc(var(--color-water-dark-l))
  );


  
}

body, #root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100vw;
  height: 100vh;
  /* background: hsl(222, 83%, 84%); */
  background: hsl(226deg 56% 32%);
  /* background: radial-gradient(hsl(222, 83%, 84%), hsl(226deg 56% 32%)); */
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
